<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field type="text" hide-details filled label="Абонент" disabled v-model="flat_name" color="grey"
            />
          </v-col>
          <v-col cols="12" md="12">
            <ACC_ContractorContract :value="contract_id" @autocompleteChange="contractChange" :person_hash="person_hash"
                                    :contractor_name="flat_name"
                                  :class="contract_id ? '' : 'req-star'" color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <AC_FlatIndicatorType :value="indicator_id" @autocompleteChange="flatIndicatorTypeChange"
                                  :class="indicator_id ? '' : 'req-star'" color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Значення" v-model="valueDec"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="valueDec ? '' : 'req-star'"
                          color="grey"
                          v-decimal
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="dateStart" req label="Дата початку"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="dateEnd" label="Дата закінчення"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createFlatIndicator" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {
  CREATE_LEGACY_FLAT_INDICATOR,
  UPDATE_LEGACY_FLAT_INDICATOR,
  DELETE_LEGACY_FLAT_INDICATOR,
  FETCH_LEGACY_FLAT_INDICATORS
} from "@/store/actions/flat_indicators";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {GET_ALL_WITH_BALANCE_LEGACY, GET_ARCHIVE_LEGACY} from "@/store/actions/flat_service";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'legacy_flat_indicator__modal_delete'

export default {
  name: "HWP_Modal_LegacyFlatIndicator",
  mixins: [ModalComponentMixin],
  components: {
    ACC_ContractorContract: () => import("@/components/accounting/autocomplite/modal/ACC_ContractorContract"),
    AC_FlatIndicatorType: () => import("@/components/autocomplite/AC_FlatIndicatorType"),
  },
  data() {
    return {
      navigation: false,
      flat_name: this.item.flat_name,
      person_hash: this.item.person_hash,
      contract_id: this.item.contract_id || null,
      indicator_id: this.item.indicator_id,
      valueDec: this.item.value_dec || 0,
      dateStart: this.item.date_start || '',
      dateEnd: this.item.date_end || '',
      actual: this.item.actual || false,
    }
  },
  computed: {
    ...mapGetters({
      indicator_select: 'getFlatIndicatorTypes',
      general_settings: 'getGeneralSettings'
    }),
  },
  methods: {
    ...mapActions({
      fetchFlatServices: GET_ALL_WITH_BALANCE_LEGACY,
      getAccountArchive: GET_ARCHIVE_LEGACY,
    }),
    contractChange(payload) {
      this.contract_id = payload?.value || null
    },
    flatIndicatorTypeChange(payload) {
      this.indicator_id = payload?.value || null
    },
    closeModal(payload = null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.flat_name = this.item.flat_name
      this.contract_id = this.item.contract_id || null
      this.person_hash = this.item.person_hash
      this.indicator_id = this.item.indicator_id
      this.valueDec = this.item.value_dec
      this.dateStart = this.item.date_start
      this.dateEnd = this.item.date_end
      this.actual = this.item.actual || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення нормативного показника ${this.getIndicatorName()}: ${this.valueDec}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    getIndicatorName() {
      if (this.indicator_id) {
        const ind = this.indicator_select.find(item => item.value === this.indicator_id)
        if (ind) {
          return ind.text
        }
      }
      return ''
    },
    createFlatIndicator() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Вид показника, договір, значення та дата початку - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        person_hash: this.person_hash,
        indicator_id: this.indicator_id,
        contract_id: this.contract_id,
        value_dec: this.valueDec,
        date_start: this.dateStart,
        date_end: this.dateEnd ? this.dateEnd : null
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_LEGACY_FLAT_INDICATOR, payload)
            .then(res => {
              if (res) {
                this.$store.dispatch(FETCH_LEGACY_FLAT_INDICATORS, this.person_hash)
                    .then(res => {
                      if (res) {
                        // const person_hash = this.$route.params.person_hash
                        // if (person_hash) {
                        //   this.fetchFlatServices(person_hash)
                        //   this.getAccountArchive(person_hash)
                        // }
                        this.closeModal("fetch")
                      }
                    })
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_LEGACY_FLAT_INDICATOR, payload)
            .then(res => {
              if (res) {
                // const person_hash = this.$route.params.person_hash
                // if (person_hash) {
                //   this.fetchFlatServices(person_hash)
                //   this.getAccountArchive(person_hash)
                // }
                this.closeModal("fetch")
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.flat_name = payload.flat_name
              this.contract_id = payload.contract_id || null
              this.person_hash = payload.person_hash
              this.indicator_id = payload.indicator_id
              this.valueDec = payload.value_dec
              this.dateStart = payload.date_start
              this.dateEnd = payload.date_end
              this.actual = payload.actual || false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_LEGACY_FLAT_INDICATOR, this.itemId)
                      .then(ok => {
                        if (ok) {
                          // const person_hash = this.$route.params.person_hash
                          // if (person_hash) {
                          //   this.fetchFlatServices(person_hash)
                          //   this.getAccountArchive(person_hash)
                          // }
                          this.closeModal("fetch")
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
                if (this.isNew) {
                  this.indicator_id = this.general_settings?.general_indicator?.value || null
                }
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
